<template>
    <div class="my-textarea">
        <textarea ref="textarea" :value="value"></textarea>
    </div>
</template>

<script>
import { MyEditor } from './javascript.js';

export default {
    props: {
        // value: {
        //     type: String,
        //     default: ''
        // }
    },
    data(){
        return {
            text: '',
            obj: {
                name: '许晓峰',
                age: 28
            },
            value: '啊实打实大师asdasda\nsdasd12312413123123',
            historyList: [],
            historyIndex: -1,
            lastSpeKey: '',
            lastAction: '',
            editor: null,
        }
    },
    computed: {
        textarea(){
            return this.$refs.textarea;
        },
        tabStr(){
            let str = '';
            for (let i = 0; i < 4; i++) {
                str += ' ';
            }
            return str;
        }
    },
    created(){
        this.historyList.push({
            position: this.value.length,
            value: this.value
        })
        this.historyIndex = 0;
    },
    mounted(){
        this.editor = new MyEditor(this.$refs.textarea);
        console.log(this.editor);
    },
    methods: {
        input(event){
            console.log('input');
            // console.log(this.lastSpeKey);


            
        },
        testing(text){
            const charArr = text.split('');
            if(charArr[0] === '{' && charArr[charArr.length - 1] === '}'){
                return 'json';
            }else if(charArr[charArr.length - 1] === '}'){
                return 'code';
            }else{
                return 'text';
            }
        },
        keydown(event){
            console.log('keydown');
            return;
            // console.log(event);
            const position = this.getCaretPosition(event.target);
            if(event.ctrlKey && event.key === 'z'){
                this.historyIndex --;
                if(this.historyIndex < 0){
                    this.historyIndex = 0;
                    return;
                }
                event.preventDefault();
                console.log('后退');
                this.textarea.value = this.historyList[this.historyIndex].value;
                console.log(this.historyList[this.historyIndex].position);
                this.setCaretPosition(this.textarea,this.historyList[this.historyIndex].position);
                return;
            }
            if(event.ctrlKey && event.key === 'y'){
                this.historyIndex ++;
                if(this.historyIndex >= this.historyList.length){
                    this.historyIndex = this.historyList.length;
                    return;
                }
                event.preventDefault();
                console.log('前进');
                this.textarea.value = this.historyList[this.historyIndex].value;
                this.setCaretPosition(this.textarea,this.historyList[this.historyIndex].position);
                return;
            }
            
            switch(event.key){
                case 'Tab':
                    event.preventDefault();
                    this.textarea.setRangeText(this.tabStr,position,position);
                    this.setCaretPosition(event.target,position + 4);
                break;
                case 'Enter':
                    // event.preventDefault();
                    // this.xxf(event);
                break;
                case '{':
                    event.preventDefault();
                    this.textarea.setRangeText('{}',position,position);
                    this.setCaretPosition(event.target,position + 1);
                break;
                case '[':
                    event.preventDefault();
                    this.textarea.setRangeText('[]',position,position);
                    this.setCaretPosition(event.target,position + 1);
                break;
                // case ' ':
                //     event.preventDefault();
                //     this.textarea.setRangeText(' ',position,position);
                //     this.setCaretPosition(event.target,position + 1);
                // break;
            }
            const speKeys = ['Tab','Enter','{','['];
            if(speKeys.includes(event.key)){
                this.handleHistory(event.key);
                this.lastSpeKey = event.key;      //  记录上一次的按键
                this.lastAction = '';
            }
            // const continuousKeys = [' ','Backspace'];
            // if(continuousKeys.includes(event.key)){
            //     if(this.lastSpeKey === event.key){
            //         //  
            //     }
            //     this.lastSpeKey = event.key;      //  记录上一次的按键
            // }
            
            
            // console.log('keydown');
            
        },
        getRowPosition(){
            const textarea = this.$refs.textarea;
            const position = this.getCaretPosition(textarea);       //  当前光标位置（光标位置 - 1 = 字符下标位置）
            const text = textarea.value;
            const chars = text.split('');

            let startPosition = position - 1;               //  获取当前光标行位置
            let startTemp = chars[startPosition];
            
            let endPosition = position - 1;
            let endTemp = chars[startPosition];
            let rowIndex = -1;      //  初始值为-1，因为0也是一个正常下标
            while(true){
                if(!(startTemp === '\n' || !startTemp)){
                    startPosition -= 1;
                    startTemp = chars[startPosition];
                    rowIndex += 1;
                }
                if(!(endTemp === '\n' || !endTemp)){
                    endPosition += 1;
                    endTemp = chars[endPosition];
                }

                if((startTemp === '\n' || !startTemp) && (endTemp === '\n' || !endTemp)){
                    break;
                }
            }
            const rowChars = [];
            for (let i = startPosition + 1; i <= endPosition - 1; i++) {
                rowChars.push(chars[i]);
            }

            return {
                startPosition,
                endPosition,
                rowChars,
                rowIndex,           //  当前行的光标index
            }
        },
        xxf(event){
            let position = this.getCaretPosition(event.target);       //  当前光标位置
            const tabSize = 4;
            
            if(position !== 0){
                const { startPosition , endPosition , rowChars , rowIndex } = this.getRowPosition();
                // console.log(rowChars);
                // console.log('rowIndex',rowIndex);       
                // console.log(rowChars.map(item => item.charCodeAt()));;
                let spaceCount = 0;
                let hasBracket = false;
                for (let i = 0; i < rowChars.length; i++) {
                    if(rowChars[i] !== ' '){
                        spaceCount = i;
                        break;
                    }
                }
                for (let i = rowIndex; i >= 0; i--) {
                    if(rowChars[i] === '{' || rowChars[i] === '['){
                        hasBracket = true;
                        break;
                    }
                }
                
                let spaceStr = '';
                for (let i = 0; i < spaceCount; i++) {
                    spaceStr += ' ';
                }
                let bracketStr = '';
                if(hasBracket){
                    bracketStr = '    ' + '\n' + spaceStr;
                }
                const resStr = '\n' + spaceStr + bracketStr;
                // console.log('spaceCount',spaceCount);
                // console.log('hasBracket',hasBracket);
                this.textarea.setRangeText(resStr,position,position);      //  这次操作是回车，这里会多一个换行字符
                this.setCaretPosition(event.target,position + spaceCount + 1 + (hasBracket ? tabSize : 0));
            }
        },
        wait(){
            return new Promise((reoslve,reject) => {
                setTimeout(() => {
                    reoslve();
                })
            })
        },
        handleHistory(key){
            // await this.wait();
            const position = this.getCaretPosition(this.textarea);
            const value = this.textarea.value;
            console.log('position',position);
            console.log('value',value);
            //  连续的空格,连续的删除,tab,enter,{},
            const historyKeys = ['Enter','Tab','{',' ',];        //  特殊字符

            if(this.historyIndex !== this.historyList.length - 1){      //  当前不是最新历史进度(进行了前进后退等操作)
                this.historyList.splice(this.historyIndex + 1);         //  删除当前历史记录下标之后的历史记录
            }

            if(historyKeys.includes(key)){      //  这次按键为特殊字符
                if(key === ' ' && this.lastSpeKey === ' '){     //  这次按键与上次按键都为空格(算作一次历史)---修改当前历史

                }else{      //  添加历史
                    this.addHistory();
                }
            }else{
                if(historyKeys.includes(this.lastSpeKey)){      //  上次按键为特殊字符(且有真实字符输入)---添加历史
                    this.addHistory();

                }else{      //  上次按键为非特殊字符---修改当前历史
                    const history = this.historyList[this.historyIndex];
                    history.position = position;
                    history.value = value;
                }
            }

        },
        addHistory(){
            const position = this.getCaretPosition(this.textarea);
            const value = this.textarea.value;
            this.historyList.push({
                position,
                value
            })
            this.historyIndex ++;
            console.log(this.historyList);
        },
        editHistory(){
            console.log('editHistory');
            const history = this.historyList[this.historyIndex];
            const position = this.getCaretPosition(this.textarea);
            const value = this.textarea.value;
            history.position = position;
            history.value = value;
        },
        getCaretPosition(el) {      //  获取光标
            var caretPos = 0;
            if (el.selectionStart) {
                caretPos = el.selectionStart;
            } else if (document.selection) {
                el.focus();
                var rng = document.selection.createRange();
                rng.moveStart('character', -el.value.length);
                caretPos = rng.text.length;
            }
            return caretPos;
        },
        setCaretPosition(el, index) {       //  设置光标
            if (el.setSelectionRange) {
                el.focus();
                el.setSelectionRange(index, index);
            } else if (el.createTextRange) {
                var range = el.createTextRange();
                range.collapse(true);
                range.moveStart('character', index);
                range.select();
            }
        },
    },
}
</script>

<style lang="less" scoped>
.my-textarea{
    padding: 10px;
    border: 1px solid rgb(204, 204, 204);
    textarea{
        width: 100%;
        height: 420px;
        font-size: 14px;
        color: #333;
        background-color: #f5f2f0;
        border: none;
        padding: 14px;
        line-height: 1.5;
        font-family: Consolas,Monaco,Andale Mono,Ubuntu Mono,monospace;
        box-sizing: border-box;
        
    }
    textarea:focus{
        outline: none;
    }
}
</style>