function xf(){

}

const color1 = '#C586C0'

const list1 = ['function','const','let']

const color2 = '#C586C0'

['break','']

const color3 = '#4FC1FF';   //  变量

//  



const inputChangeTypeList = [
    {
        value: 'insertText',
        label: '输入字符',
    },
    {
        value: 'deleteContentBackward',
        label: '删除'
    },
    {
        value: 'insertFromPaste',
        label: '粘贴功能'
    },
    {
        value: 'deleteByCut',
        label: '剪切（删除）'
    },
    {
        value: 'insertLineBreak',
        label: '插入换行'
    },
]



export class MyEditor {
    constructor(textarea){
        this.textarea = textarea;
        this.lastInputType = '';        //  上次input触发的type
        this.lastData = '';             //  上次input触发改变的value
        this.historyList = [];
        this.historyIndex = -1;
        this.addHistory();

        this.textarea.addEventListener('input',this.input.bind(this));
        this.textarea.addEventListener('keydown',this.keydown.bind(this));
    }
    input(event){
        console.log('input',event);
        // return;
        const inputType = event.inputType;
        const data = event.data;
        const speKeys = ['insertFromPaste','deleteByCut','insertLineBreak','deleteContentBackward'];
        if(speKeys.includes(inputType)){
            if(inputType === 'insertLineBreak'){
                this.handleEnter();
            }
            if(inputType === 'deleteContentBackward' && inputType === this.lastInputType){      //  删除判断是否连续，连续算作一次历史
                this.editHistory();
            }else{
                this.addHistory();
            }
        }
        if(inputType === 'insertText'){
            if(data === ' '){           //  输入空格
                if(data === this.lastData){
                    this.editHistory();
                }else{
                    this.addHistory();
                }
            }else{
                if(speKeys.includes(this.lastInputType) || this.lastData === ' '){
                    this.addHistory();
                }else{
                    this.editHistory();
                }
            }
        }
        

        this.lastInputType = inputType;
        this.lastData = event.data;

        
    }
    keydown(event){
        if(event.ctrlKey && event.key === 'z'){
            this.historyIndex --;
            if(this.historyIndex < 0){
                this.historyIndex = 0;
                return;
            }
            event.preventDefault();
            console.log('后退');
            this.textarea.value = this.historyList[this.historyIndex].value;
            console.log(this.historyList[this.historyIndex].position);
            this.setCaretPosition(this.textarea,this.historyList[this.historyIndex].position);
            return;
        }
        if(event.ctrlKey && event.key === 'y'){
            this.historyIndex ++;
            if(this.historyIndex >= this.historyList.length){
                this.historyIndex = this.historyList.length;
                return;
            }
            event.preventDefault();
            console.log('前进');
            this.textarea.value = this.historyList[this.historyIndex].value;
            this.setCaretPosition(this.textarea,this.historyList[this.historyIndex].position);
            return;
        }

        if(event.key === 'Tab'){
            event.preventDefault();
            const position = this.getCaretPosition(this.textarea);
            this.textarea.setRangeText(this.getTabStr(),position,position);
            this.setCaretPosition(event.target,position + 4);
        }
    }
    handleEnter(event){
        let position = this.getCaretPosition();       //  当前光标位置
        const tabSize = 4;
        
        if(position !== 0){
            const { startPosition , endPosition , rowChars , rowIndex } = this.getRowPosition();
            // console.log(rowChars);
            // console.log('rowIndex',rowIndex);       
            // console.log(rowChars.map(item => item.charCodeAt()));;
            let spaceCount = 0;
            let hasBracket = false;
            for (let i = 0; i < rowChars.length; i++) {
                if(rowChars[i] !== ' '){
                    spaceCount = i;
                    break;
                }
            }
            for (let i = rowIndex; i >= 0; i--) {
                if(rowChars[i] === '{' || rowChars[i] === '['){
                    hasBracket = true;
                    break;
                }
            }
            
            let spaceStr = '';
            for (let i = 0; i < spaceCount; i++) {
                spaceStr += ' ';
            }
            let bracketStr = '';
            if(hasBracket){
                bracketStr = '    ' + '\n' + spaceStr;
            }
            const resStr = '\n' + spaceStr + bracketStr;
            // console.log('spaceCount',spaceCount);
            // console.log('hasBracket',hasBracket);
            this.textarea.setRangeText(resStr,position,position);      //  这次操作是回车，这里会多一个换行字符
            this.setCaretPosition(event.target,position + spaceCount + 1 + (hasBracket ? tabSize : 0));
        }
    }
    addHistory(){
        const position = this.getCaretPosition(this.textarea);
        const value = this.textarea.value;
        this.historyList.push({
            position,
            value
        })
        this.historyIndex ++;
        console.log(this.historyList);
    }
    editHistory(){
        const history = this.historyList[this.historyIndex];
        const position = this.getCaretPosition(this.textarea);
        const value = this.textarea.value;
        history.position = position;
        history.value = value;
    }
    
    getCaretPosition() {      //  获取光标
        const el = this.textarea;
        var caretPos = 0;
        if (el.selectionStart) {
            caretPos = el.selectionStart;
        } else if (document.selection) {
            el.focus();
            var rng = document.selection.createRange();
            rng.moveStart('character', -el.value.length);
            caretPos = rng.text.length;
        }
        return caretPos;
    }
    setCaretPosition(el, index) {       //  设置光标
        if (el.setSelectionRange) {
            el.focus();
            el.setSelectionRange(index, index);
        } else if (el.createTextRange) {
            var range = el.createTextRange();
            range.collapse(true);
            range.moveStart('character', index);
            range.select();
        }
    }
    getTabStr(){
        let str = '';
        for (let i = 0; i < 4; i++) {
            str += ' ';
        }
        return str;
    }
    getRowPosition(){
        const textarea = this.textarea;
        const position = this.getCaretPosition(textarea);       //  当前光标位置（光标位置 - 1 = 字符下标位置）
        const text = textarea.value;
        const chars = text.split('');
    
        let startPosition = position - 1;               //  获取当前光标行位置
        let startTemp = chars[startPosition];
        
        let endPosition = position - 1;
        let endTemp = chars[startPosition];
        let rowIndex = -1;      //  初始值为-1，因为0也是一个正常下标
        while(true){
            if(!(startTemp === '\n' || !startTemp)){
                startPosition -= 1;
                startTemp = chars[startPosition];
                rowIndex += 1;
            }
            if(!(endTemp === '\n' || !endTemp)){
                endPosition += 1;
                endTemp = chars[endPosition];
            }
    
            if((startTemp === '\n' || !startTemp) && (endTemp === '\n' || !endTemp)){
                break;
            }
        }
        const rowChars = [];
        for (let i = startPosition + 1; i <= endPosition - 1; i++) {
            rowChars.push(chars[i]);
        }
    
        return {
            startPosition,
            endPosition,
            rowChars,
            rowIndex,           //  当前行的光标index
        }
    }
}
